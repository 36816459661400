import { Component, computed, inject, signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterOutlet } from '@angular/router'
import { ModalProductUpdateFromMasterComponent } from '@shared/components/v1/modal-product-update-from-master/modal-product-update-from-master.component'
import { HasProductUpdate, ProductsService, RoleEnum, WholesaleStatusEnum } from '@core/api'
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { WarningBannerComponent } from '@shared/components/v1/warning-banner/warning-banner.component'
import { WsMenuStickyComponent } from '@pages/ws-outlet/ws-menu-sticky/ws-menu-sticky.component'
import { ActiveMenuService } from '@service/active-menu.service'

@Component({
  selector: 'app-ws-outlet',
  imports: [
    CommonModule,
    RouterOutlet,
    ModalProductUpdateFromMasterComponent,
    WarningBannerComponent,
    WsMenuStickyComponent,
  ],
  templateUrl: './ws-outlet.component.html',
})
export class WsOutletComponent {
  readonly activeMenuService = inject(ActiveMenuService)

  hasUpdate$ = this.userDataService.userData$.pipe(
    distinctUntilChanged((previous, current) => {
      return previous?.role === current?.role && previous?.myWholesaleStatus === current?.myWholesaleStatus
    }),
    filter((userData) => {
      return userData?.role === RoleEnum.Ws && userData.myWholesaleStatus === WholesaleStatusEnum.Approved
    }),
    switchMap(() => this.productsService.productsPackagesHasUpdateFromMasterRetrieve()),
    tap((result: HasProductUpdate) => {
      this.hasUpdateSignal.set(result.hasProductUpdate ?? false)
    }),
  )
  hasUpdateSignal = signal(false)

  constructor(
    private readonly productsService: ProductsService,
    private readonly userDataService: UserDataService,
  ) {}

  showUpdateModal = computed(() => {
    return localStorage.getItem('ignoreUpdateFromMaster') !== 'true' && this.hasUpdateSignal()
  })
}
