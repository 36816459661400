import { Injectable } from '@angular/core'

// Define an interface if your event parameters follow a specific structure
interface GoogleAnalyticsParams {
  user_id?: number
}

declare global {
  interface Window {
    gtag?: (command: string, eventName: string, params?: GoogleAnalyticsParams) => void
  }
}

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  constructor() {}

  sendEvent(eventName: string, eventParams: object) {
    if (window.gtag) {
      window.gtag('event', eventName, eventParams)
    }
  }
}
