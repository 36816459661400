import { CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { filter } from 'rxjs'
import { Me } from '@core/api'

export const userIsRegisteredGuard: CanActivateFn = () => {
  const userDataService = inject(UserDataService)
  const router = inject(Router)

  return new Promise((resolve) => {
    userDataService.userData$.pipe(filter((userData): userData is Me => !!userData)).subscribe((meData) => {
      if (!meData.hasProfileDetail) {
        router.navigate(['/']).then(() => {
          resolve(false)
        })
        return
      }

      resolve(true)
    })
  })
}
