import { Router } from '@angular/router'
import { inject } from '@angular/core'
import { GtWsService } from '@core/services/gt-ws/gt-ws.service'

export const gtHasNotSelectWsGuard = () => {
  const router = inject(Router)
  const gtWsService = inject(GtWsService)

  if (gtWsService.wsId) {
    router.navigate([''])
    return false
  }
  return true
}
