import { Router } from '@angular/router'
import { inject } from '@angular/core'
import { GtWsService } from '@core/services/gt-ws/gt-ws.service'

export const gtHasSelectWsGuard = () => {
  const router = inject(Router)
  const gtWsService = inject(GtWsService)
  if (!gtWsService.wsId) {
    router.navigate(['gt', 'home'])
    return false
  }
  return true
}
