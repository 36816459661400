import { inject, Injectable, OnDestroy, signal } from '@angular/core'
import {
  CouponDetailCartOrder,
  GeneralTradeCouponForCartList,
  GeneralTradesService,
  OrdersService,
  PaginatedGeneralTradeCouponForCartListList,
  PaginatedMinimumProductPackageMasterList,
} from '@core/api'
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { PaginationService } from '@core/services/pagination/pagination.service'
import { GtWsService } from '@core/services/gt-ws/gt-ws.service'

type OrderCouponIdPair = {
  orderId: number | null
  couponId: number | null
}

@Injectable()
export class CouponService implements OnDestroy {
  private readonly gtWsService = inject(GtWsService)
  private destroyRef$ = new Subject<void>()

  private _gtCouponList$ = new BehaviorSubject<GeneralTradeCouponForCartList[]>([])
  public gtCouponIdDetail$ = new Subject<number | null>()
  private _gtCouponDetail$ = new BehaviorSubject<CouponDetailCartOrder | null>(null)
  private _packagePaginateData$ = new BehaviorSubject<PaginatedMinimumProductPackageMasterList | null>(null)
  public _isLoadingCouponList$ = new BehaviorSubject<boolean>(false)
  public _isLoadingCouponDetail$ = new BehaviorSubject<boolean>(false)
  public _isLoadingPackage$ = new BehaviorSubject<boolean>(true)

  public gtCouponDetail$ = this._gtCouponDetail$.asObservable()
  public gtCouponList$ = this._gtCouponList$.asObservable()
  public packagePaginateData$ = this._packagePaginateData$.asObservable()
  public isLoadingCouponDetail$ = this._isLoadingCouponDetail$.asObservable()
  public isLoadingCouponList$ = this._isLoadingCouponList$.asObservable()
  public isLoadingPackage$ = this._isLoadingPackage$.asObservable()

  public orderAndCouponIdPairForOrderCouponDetail$ = new Subject<OrderCouponIdPair>()
  public orderAndCouponIdPairForOrderCouponDetailObs$ = this.orderAndCouponIdPairForOrderCouponDetail$.asObservable()

  gtCouponListNext = signal<string | null>(null)

  constructor(
    private generalTradesService: GeneralTradesService,
    private ordersService: OrdersService,
    private paginationService: PaginationService,
  ) {
    this.initOnChangeCouponDetailId()
    this.initOnCurrentCouponChangeGetProductList()
    this.initOnOrderIdForCouponDetail()
  }

  private get wsId() {
    return this.gtWsService.wsId ?? 0
  }

  ngOnDestroy() {
    this.destroyRef$.next()
    this.destroyRef$.complete()
  }

  fetchGTCouponList(pageSize?: number) {
    this._isLoadingCouponList$.next(true)
    this.generalTradesService
      .generalTradesWholesaleCartCouponsList({
        wholesaleId: this.wsId,
        pageSize,
      })
      .pipe(
        finalize(() => {
          this._isLoadingCouponList$.next(false)
        }),
      )
      .subscribe((resp: PaginatedGeneralTradeCouponForCartListList) => {
        this._gtCouponList$.next(resp.results ?? [])
        this.gtCouponListNext.set(resp.next ?? null)
      })
  }

  fetchGTCouponListPaginationNext() {
    const gtCouponListNext = this.gtCouponListNext()
    if (!gtCouponListNext) {
      return
    }
    this.paginationService.fetchNextCouponList(gtCouponListNext).subscribe({
      next: (resp) => {
        const { next, results } = resp
        this.gtCouponListNext.set(next ?? null)
        this._gtCouponList$.next(this._gtCouponList$.getValue().concat(results ?? []))
      },
      error: () => {},
    })
  }

  private initOnOrderIdForCouponDetail() {
    this.orderAndCouponIdPairForOrderCouponDetailObs$
      .pipe(
        takeUntil(this.destroyRef$),
        distinctUntilChanged((previous, current) => this.isOrderAndCouponIdTheSame(previous, current)),
        filter(({ orderId, couponId }) => this.ifOrderIdOrCouponIdIsNullResetCouponDetail(orderId, couponId)),
        tap(() => this._isLoadingCouponDetail$.next(true)),
        switchMap(({ orderId, couponId }) =>
          this.ordersService.ordersCouponsRetrieve({ id: couponId!, orderId: orderId! }).pipe(
            finalize(() => {
              this._isLoadingCouponDetail$.next(false)
            }),
          ),
        ),
        catchError(() => of(null)),
      )
      .subscribe((resp) => {
        this._gtCouponDetail$.next(resp)
      })
  }

  private initOnChangeCouponDetailId() {
    this.gtCouponIdDetail$
      .pipe(
        takeUntil(this.destroyRef$),
        distinctUntilChanged(),
        filter((couponId) => {
          return this.ifCouponIdIsNullResetCouponDetail(couponId)
        }),
        tap(() => this._isLoadingCouponDetail$.next(true)),
        switchMap((couponId) =>
          this.generalTradesService
            .generalTradesWholesaleCartCouponsRetrieve({
              wholesaleId: this.wsId,
              id: couponId!.toString(),
            })
            .pipe(
              finalize(() => {
                this._isLoadingCouponDetail$.next(false)
              }),
            ),
        ),
        catchError(() => of(null)),
      )
      .subscribe((result) => {
        this._gtCouponDetail$.next(result)
      })
  }

  private initOnCurrentCouponChangeGetProductList() {
    this.gtCouponIdDetail$
      .pipe(
        takeUntil(this.destroyRef$),
        distinctUntilChanged(),
        tap(() => this._isLoadingPackage$.next(true)),
        filter((couponId) => {
          return this.ifCouponIdIsNullThenDisableLoadingAndClearProductData(couponId)
        }),
        switchMap((couponId) => {
          return this.generalTradesService
            .generalTradesWholesaleCartCouponsProductPackagesList({
              wholesaleId: this.wsId,
              couponId: couponId!,
            })
            .pipe(
              finalize(() => {
                this._isLoadingPackage$.next(false)
              }),
            )
        }),
        catchError(() => of(null)),
      )
      .subscribe((resp) => {
        this._packagePaginateData$.next(resp)
      })

    this.orderAndCouponIdPairForOrderCouponDetailObs$
      .pipe(
        takeUntil(this.destroyRef$),
        distinctUntilChanged((previous, current) => this.isOrderAndCouponIdTheSame(previous, current)),
        filter(({ orderId, couponId }) =>
          this.ifOrderIdOrCouponIdIsNullDisableLoadingAndClearProductData(orderId, couponId),
        ),
        tap(() => this._isLoadingCouponDetail$.next(true)),
        switchMap((orderCouponPair) => {
          return this.generalTradesService
            .generalTradesWholesaleCartCouponsProductPackagesList({
              wholesaleId: this.wsId,
              couponId: orderCouponPair.couponId!,
            })
            .pipe(
              finalize(() => {
                this._isLoadingPackage$.next(false)
              }),
            )
        }),
        catchError(() => of(null)),
      )
      .subscribe((resp) => {
        this._packagePaginateData$.next(resp)
      })
  }

  private isOrderAndCouponIdTheSame(previous: OrderCouponIdPair, current: OrderCouponIdPair) {
    return previous.orderId === current.orderId && previous.couponId === current.couponId
  }

  private ifCouponIdIsNullResetCouponDetail(couponId: number | null): boolean {
    if (!couponId) {
      this._gtCouponDetail$.next(null)
    }
    return !!couponId
  }

  private ifCouponIdIsNullThenDisableLoadingAndClearProductData(couponId: number | null): boolean {
    if (!couponId) {
      this._packagePaginateData$.next(null)
      this._isLoadingPackage$.next(false)
    }
    return !!couponId
  }

  private ifOrderIdOrCouponIdIsNullResetCouponDetail(orderId: number | null, couponId: number | null): boolean {
    if (!couponId || !orderId) {
      this._gtCouponDetail$.next(null)
      return false
    }
    return true
  }

  private ifOrderIdOrCouponIdIsNullDisableLoadingAndClearProductData(
    orderId: number | null,
    couponId: number | null,
  ): boolean {
    if (!couponId || !orderId) {
      this._packagePaginateData$.next(null)
      this._isLoadingPackage$.next(false)
      return false
    }
    return true
  }
}
