import { Component, computed, model, OnDestroy, OnInit, signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Router, RouterModule } from '@angular/router'
import { PositionEnum, WholesaleNotification, WholesalesService } from '@core/api'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { Subject, takeUntil } from 'rxjs'
import { MenuItem } from '@shared/const/menu-item'

@Component({
  selector: 'app-ws-menu-sticky',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: '../../../shared/components/v1/menu-sticky/menu-sticky.component.html',
})
export class WsMenuStickyComponent implements OnInit, OnDestroy {
  value = model<string>('')
  protected menuItem = signal<MenuItem[]>([
    {
      imgSrc: 'assets/icons/order.svg',
      activeImgSrc: 'assets/icons/order-act.svg',
      label: 'คำสั่งซื้อใหม่',
      value: 'new-order',
      order: 1,
    },
    {
      imgSrc: 'assets/icons/invoice.svg',
      activeImgSrc: 'assets/icons/invoice-act.svg',
      label: 'ประวัติสั่งซื้อ',
      value: 'order-history',
      order: 2,
    },
    {
      imgSrc: 'assets/icons/shop-setting.svg',
      activeImgSrc: 'assets/icons/shop-setting-act.svg',
      label: 'จัดการร้านค้า',
      value: 'ws',
      order: 4,
    },
  ])

  protected gridColumn = computed(() => {
    return `grid-cols-${this.menuItem().length}`
  })

  destroyed$ = new Subject<void>()

  constructor(
    private router: Router,
    private wholesalesService: WholesalesService,
    private userDataService: UserDataService,
  ) {}

  ngOnInit() {
    this.subscribeUserData()
    if (this.userDataService.currentUserData) {
      this.wholesalesService
        .wholesalesNotificationRetrieve({
          id: this.userDataService.currentUserData!.myWholesaleId!,
        })
        .subscribe((response: WholesaleNotification) => {
          this.overrideNumOfOrder(response.newOrderCounts)
        })
    }
  }

  ngOnDestroy() {
    this.destroyed$.next()
    this.destroyed$.subscribe()
  }

  subscribeUserData() {
    this.userDataService.userData$.pipe(takeUntil(this.destroyed$)).subscribe((userData) => {
      if ([PositionEnum.ShopOwner, PositionEnum.ShopManager].includes(userData?.position as PositionEnum)) {
        const menus = [
          ...this.menuItem(),
          {
            imgSrc: 'assets/icons/discount.svg',
            activeImgSrc: 'assets/icons/discount-act.svg',
            label: 'จัดการโปรโมชั่น',
            value: 'promotion',
            order: 3,
          },
        ]
        menus.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        this.menuItem.set(menus)
      }
    })
  }

  overrideNumOfOrder(newOrder: number) {
    const menus = this.menuItem()
    menus[0] = {
      ...menus[0],
      numOfOrder: newOrder,
    }
    this.menuItem.set(menus)
  }

  click(value: string) {
    this.value?.set(value)
    switch (value) {
      case 'order-history':
        return this.router.navigate(['ws', 'order', 'history'])
      case 'new-order':
        return this.router.navigate(['ws', 'order', 'waiting'])
      case 'promotion':
        return this.router.navigate(['ws', 'promotion'])
      default:
        return this.router.navigate(['ws'])
    }
  }
}
