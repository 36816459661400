import { ButtonComponent } from '@shared/components/v1/button/button.component'
import { Component, EventEmitter, Input, Output, computed } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '@shared/components/v1/icon/icon.component'
import { CloseButtonHeaderComponent } from '@shared/components/v1/close-button-header/close-button-header.component'

export type ConfirmDialogType = 'default' | 'success'
export const CONFIRM_DIALOG_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
}

@Component({
  selector: 'app-confirm-dialog',
  imports: [CommonModule, ButtonComponent, IconModule, CloseButtonHeaderComponent],
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  @Input() type: ConfirmDialogType = 'default'
  @Input() showOutline: boolean = false
  @Input() title?: string
  @Input() description?: string
  @Input() buttonText: string = 'ตกลง'
  @Input() icon?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() closeCallback?: Function
  @Output() handleClose = new EventEmitter<void>()

  CONFIRM_DIALOG_TYPE = CONFIRM_DIALOG_TYPE

  iconName = computed(() => {
    if (this.icon) {
      return this.icon
    }
    return 'checkCircle'
  })

  get modalClasses(): string[] {
    return [
      'font-sans',
      'overflow-x-hidden',
      'overflow-y-auto fixed',
      'inset-0',
      'z-1000',
      'outline-none',
      'focus:outline-none',
      'justify-center',
      'items-center',
      'flex',
    ]
  }

  close() {
    this.handleClose.emit()
    if (this.closeCallback) {
      this.closeCallback()
    }
  }
}
