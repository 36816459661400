<div class="top-0 sticky z-49 flex flex-col items-center h-{360} bg-white">
  <app-logo />
</div>

<div class="p-5">
  <app-card>
    <div content>
      <div class="flex flex-col items-center gap-4">
        <div class="text-2xl font-bold">ยินดีต้อนรับสู่รวมมิตร</div>

        <img src="/assets/images/gtRegister.svg" alt="gt register" />

        @if (rejectShopMessage) {
          <div class="bg-[#F7F7F7] my-2">
            <div class="flex p-2 gap-2">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path
                    d="M12.5 1.5C6.70156 1.5 2 6.20156 2 12C2 17.7984 6.70156 22.5 12.5 22.5C18.2984 22.5 23 17.7984 23 12C23 6.20156 18.2984 1.5 12.5 1.5ZM11.75 6.9375C11.75 6.83437 11.8344 6.75 11.9375 6.75H13.0625C13.1656 6.75 13.25 6.83437 13.25 6.9375V13.3125C13.25 13.4156 13.1656 13.5 13.0625 13.5H11.9375C11.8344 13.5 11.75 13.4156 11.75 13.3125V6.9375ZM12.5 17.25C12.2056 17.244 11.9253 17.1228 11.7192 16.9125C11.5132 16.7022 11.3977 16.4195 11.3977 16.125C11.3977 15.8305 11.5132 15.5478 11.7192 15.3375C11.9253 15.1272 12.2056 15.006 12.5 15C12.7944 15.006 13.0747 15.1272 13.2808 15.3375C13.4868 15.5478 13.6023 15.8305 13.6023 16.125C13.6023 16.4195 13.4868 16.7022 13.2808 16.9125C13.0747 17.1228 12.7944 17.244 12.5 17.25Z"
                    fill="#FAAD14"
                  />
                </svg>
              </div>
              <div class="flex flex-col">
                <p class="font-bold">การสมัครของคุณไม่ถูกอนุมัติ</p>
                <div class="flex gap-2">
                  <div class="text-text-disabled">เหตุผล:</div>
                  <div>{{ rejectShopMessage }}</div>
                </div>
              </div>
            </div>
          </div>
        }

        <div class="w-full">
          <div class="flex flex-col items-center">
            <div class="text-lg font-bold mb-2" data-test-id="new-gt-shop">เปิดร้านค้าปลีกเพื่อซื้อสินค้า</div>

            <div class="mb-4 text-center w-full">
              <div class="leading-snug">สินค้าครบครัน ราคามาตรฐานและโปรโมชั่นดีๆ</div>
              <div class="leading-snug">กำลังรอคุณมาช็อปปิ้งอยู่ สมัครสมาชิกเลย</div>
            </div>

            <app-button
              data-test-id="register-gt-button"
              class="w-full"
              buttonType="primary"
              buttonSize="l-full"
              label="เปิดร้านค้าปลีก"
              (clickButton)="goToRegisterGT()"
            ></app-button>
          </div>
        </div>
      </div>

      <div class="my-4 grid gap-1 h-6 grid-cols-or-divider">
        <div class="border-b border-solid self-center"></div>
        <div class="text-border-normal text-center content-center text-xs bg-light-gray rounded-3xl">หรือ</div>
        <div class="border-b border-solid self-center"></div>
      </div>

      <div class="flex flex-col items-center">
        <div class="mb-4 text-center max-w-[296px]">
          <div class="leading-snug">ต้องการขายสินค้า</div>
          <div class="leading-snug">เปิดร้านค้าส่งเพื่อรับสิทธิประโยชน์มากมาย</div>
        </div>

        <app-button
          data-test-id="register-ws-button"
          class="w-full"
          buttonType="tertiary"
          buttonSize="l-full"
          label="เปิดร้านค้าส่ง"
          (clickButton)="goToRegisterWS()"
        ></app-button>
      </div>
    </div>
  </app-card>
</div>
