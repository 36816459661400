import { Component, inject } from '@angular/core'
import { ButtonComponent } from '@shared/components/v1/button/button.component'
import { ModalComponent } from '@shared/components/v1/modal/modal.component'
import { Router } from '@angular/router'
import { CookieConsentService } from '@service/cookie-consent.service'

@Component({
  selector: 'app-cookie-consent-modal',
  imports: [ButtonComponent, ModalComponent],
  templateUrl: './cookie-consent-modal.component.html',
})
export class CookieConsentModalComponent {
  readonly router = inject(Router)
  readonly cookieConsentService = inject(CookieConsentService)

  goToCookieSetting() {
    this.cookieConsentService.setShownCookieConsentModal(true)
    this.router.navigate(['tos/cookie-settings'])
  }
}
