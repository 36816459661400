import { homeGuard } from '@core/guards/home.guard'
import { WsOutletComponent } from '@pages/ws-outlet/ws-outlet.component'
import { HomePageComponent } from '@pages/home-page/home-page.component'
import { gtAuthDefaultGuard } from '@pages/gt-outlet/routes/const'
import { gtHasSelectWsGuard } from '@core/guards/gt-has-select-ws.guard'
import { gtHasNotSelectWsGuard } from '@core/guards/gt-has-not-select-ws.guard'
import { GtMainWrapperComponent } from '@pages/gt-outlet/gt-main-wrapper/gt-main-wrapper.component'

export const routes = [
  {
    path: 'gt',
    children: [
      {
        path: 'home',
        canActivate: [...gtAuthDefaultGuard, gtHasNotSelectWsGuard],
        loadChildren: () => import('@pages/gt-outlet/routes/gt-management-outlet.route').then((m) => m.homeRoutes),
      },
      {
        path: '',
        canActivate: [...gtAuthDefaultGuard, gtHasSelectWsGuard],
        component: GtMainWrapperComponent,
        loadChildren: () => import('@pages/gt-outlet/routes/gt-outlet.route').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () => import('@pages/gt-outlet/routes/gt-register-outlet.route').then((m) => m.registerRoutes),
      },
    ],
  },
  {
    path: 'ws',
    children: [
      {
        path: 'register',
        loadChildren: () => import('@pages/ws-outlet/ws-outlet.route').then((m) => [...m.registerRoutes]),
      },
      {
        path: '',
        component: WsOutletComponent,
        loadChildren: () => import('@pages/ws-outlet/ws-outlet.route').then((m) => [...m.routes]),
      },
    ],
  },
  {
    path: '',
    component: HomePageComponent,
    canActivate: [homeGuard],
  },
  { path: '**', redirectTo: '/' },
]
