import { CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { RoleEnum } from '@core/api/model/roleEnum'
import { Me, WholesaleStatusEnum } from '@core/api'
import { filter } from 'rxjs'

export const homeGuard: CanActivateFn = () => {
  const userDataService = inject(UserDataService)
  const router = inject(Router)

  return new Promise((resolve) => {
    userDataService.userData$.pipe(filter((userData): userData is Me => !!userData)).subscribe((meData) => {
      if (meData.role === RoleEnum.Gt) {
        if (!meData.phoneVerified && meData.hasProfileDetail) {
          router.navigate(['gt/user-otp']).then(() => {
            resolve(false)
          })
          return
        }

        if (!meData.myGtWholesaleIds.length) {
          router.navigate(['gt/waiting-approval']).then(() => {
            resolve(false)
          })
          return
        }

        router.navigate(['gt']).then(() => {
          resolve(false)
        })
        return
      }

      if (meData.role === RoleEnum.Ws) {
        if (!meData.phoneVerified && meData.hasProfileDetail) {
          router.navigate(['ws/register/user-otp']).then(() => {
            resolve(false)
          })
          return
        }

        if (meData.myWholesaleStatus === WholesaleStatusEnum.Pending) {
          router.navigate(['ws/register/waiting-approval']).then(() => {
            resolve(false)
          })
          return
        }

        router.navigate(['ws']).then(() => {
          resolve(false)
        })
        return
      }

      resolve(true)
    })
  })
}
