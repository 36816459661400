import { Component } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CouponService } from '@core/services/coupon/coupon.service'

@Component({
  selector: 'app-gt-main-wrapper',
  standalone: true,
  imports: [RouterModule],
  template: '<router-outlet></router-outlet>',
  providers: [CouponService],
})
export class GtMainWrapperComponent {}
