import { Component, DestroyRef, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonComponent } from '@shared/components/v1/button/button.component'
import { Router } from '@angular/router'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { LogoComponent } from '@shared/components/v1/logo/logo.component'
import { CardComponent } from '@shared/components/v1/card/card.component'
import { MonitoringService } from '@service/monitoring-service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter } from 'rxjs'
import { GtWsService } from '@core/services/gt-ws/gt-ws.service'

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [CommonModule, ButtonComponent, LogoComponent, CardComponent],
  templateUrl: './home-page.component.html',
})
export class HomePageComponent implements OnInit {
  rejectShopMessage?: string = ''

  constructor(
    private readonly router: Router,
    private readonly userDataService: UserDataService,
    private readonly monitoringService: MonitoringService,
    private readonly destroyRef: DestroyRef,
    private readonly gtWsService: GtWsService,
  ) {}

  ngOnInit() {
    this.gtWsService.clearWsId()
    this.userDataService.userData$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((data) => data !== null),
      )
      .subscribe((data) => {
        this.rejectShopMessage = data!.shopRejectedReason
      })

    this.monitoringService.sendEvent('page_view', {
      page_title: 'Register home page',
    })
  }

  goToRegisterWS() {
    if (!this.userDataService.currentUserData?.hasProfileDetail) {
      this.router.navigate(['/ws/register/register-user']).then()
      return
    }

    if (!this.userDataService.currentUserData?.phoneVerified) {
      this.router.navigate(['/ws/register/user-otp']).then()
      return
    }

    this.router.navigate(['/ws/register/user-register-to-shop']).then()
  }

  goToRegisterGT() {
    if (!this.userDataService.currentUserData?.hasProfileDetail) {
      this.router.navigate(['/gt/register-user']).then()
      return
    }

    if (!this.userDataService.currentUserData?.phoneVerified) {
      this.router.navigate(['/gt/user-otp']).then()
      return
    }

    this.router.navigate(['/gt/register-shop']).then()
  }
}
